var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"justify-center px-0 pb-15 pt-0",staticStyle:{"background-color":"white","height":"100%"},attrs:{"fluid":""}},[_c('div',{staticClass:"pb-16 mb-5 pt-5",style:({
      backgroundImage:
        'radial-gradient(at top left, #bd054bbf, #790431bf),' +
        (_vm.blog && _vm.blog.hero_image ? 'url(' + _vm.blog.hero_image.url + '),' : '') +
        'radial-gradient(at top left, #bd054b, #790431)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    })},[_c('v-btn',{staticClass:"ml-5 pl-1 white--text",attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-chevron-left")]),_vm._v("Back to Blogs")],1),_c('h1',{staticClass:"text-center text-sm-h3 text-h4 white--text mb-5 mt-15 px-5"},[_vm._v(" "+_vm._s(_vm.blog ? _vm.blog.title : "")+" ")]),_c('p',{staticClass:"text-center white--text"},[_vm._v(_vm._s(_vm.postDate))])],1),(_vm.blog)?_c('div',[_c('div',{staticClass:"px-10"},[_c('div',{staticClass:"blog-block mt-8"},[_c('strong',[_vm._v(_vm._s(_vm.blog.intro))])]),_vm._l((_vm.blog.body),function(block){return _c('div',{key:block.id},[(block.type === 'heading')?_c('div',{staticClass:"text-sm-h4 mt-5 blog-block"},[_vm._v(" "+_vm._s(block.value)+" ")]):(block.type === 'paragraph')?_c('div',{staticClass:"mt-5 mx-auto blog-block",domProps:{"innerHTML":_vm._s(block.value)}}):(block.type === 'image')?_c('v-img',{staticClass:"mt-3",attrs:{"src":block.value.url,"alt":block.value.title,"max-height":"500","contain":""}}):(block.type === 'media')?_c('o-embed',{staticClass:"mt-3",attrs:{"url":block.value}}):_vm._e()],1)})],2)]):_c('v-row',{attrs:{"justify-center":""}},[_c('v-progress-circular',{staticClass:"mx-auto mt-16",attrs:{"size":"40","indeterminate":"","color":"primary"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }