
  import { Component, Vue } from "vue-property-decorator";
  import { dispatchGetBlog } from "@/store/main/actions";
  import BlogCard from "@/components/BlogCard.vue";
  import { IBlogDetails } from "@/interfaces";
  import OEmbed from "@/components/OEmbed.vue";
  import { formatDate } from "@/date-utils";

  @Component({
    components: {
      BlogCard,
      OEmbed,
    },
  })
  export default class BlogPage extends Vue {
    blog: IBlogDetails | null = null;

    get postDate() {
      return this.blog ? formatDate(this.blog.date, null) : "";
    }

    public async mounted() {
      this.blog = (await dispatchGetBlog(this.$store, +this.$route.params.id)) ?? null;
    }
  }
