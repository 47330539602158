
  import { Component, Vue, Prop, Watch } from "vue-property-decorator";
  import oembedUrls from "@/oembed-urls-regexp.json";

  @Component
  export default class OEmbed extends Vue {
    @Prop({ required: true }) public url!: string;
    html: any | null = null;

    @Watch("url")
    async onUrlChange() {
      const oembedUrl = Object.keys(oembedUrls).filter((endpoint) => {
        let foundPattern = oembedUrls[endpoint].filter((pattern) =>
          new RegExp(pattern).test(this.url),
        );
        return foundPattern.length > 0;
      })[0];
      const res = await fetch(
        oembedUrl + "?url=" + this.url + "&maxheight=600&maxwidth=1000",
      );
      const resJson = await res.json();
      this.html = resJson.html;
    }

    mounted() {
      this.onUrlChange();
    }
  }
